import React from 'react'
import '../index.css'

export default function MetaMaskNotInstalled() {
    return (
        <>
            <div className="error_msg">
                <h2>You have not installed Metamask, please install Metamask and reload the page.</h2>
            </div>
        </>
    )
}
