import React, { useState, useEffect } from 'react';
//import { getWebsiteDataFromContract } from '../blockchain'
import axios from 'axios';

import { getAdmin } from "../Admin/adminLogin";
import { useDispatch, useSelector } from "react-redux";
// css
import '../index.css'
import { Link, useNavigate } from 'react-router-dom';

let base_url = "https://api-testnet.polygonscan.com/api?"

export default function AdminView() {
  let navigate = useNavigate()
  let admins = useSelector((store) => store.Adminauth.admins);
  let admin = admins.find((e) => e.address);
  const dispatch =useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      
      //console.log(`${base_url}module=account&action=txlist&address=0x77f8fff62C0981f34d1F295515bD3bd3d3f9e5a0&startblock=0&endblock=99999999&page=1&offset=10&sort=asc`)
      //console.log(`${base_url}module=account&action=txlist&address=0x89bf94dB65B3E0930068A22B1abC5b3C648d0004&apikey=${apikey}&startblock=0&endblock=99999999&page=1&offset=5&sort=desc`);
      //const result = await axios.get(`${base_url}module=account&action=txlist&address=0x89bf94dB65B3E0930068A22B1abC5b3C648d0004&apikey=${apikey}&startblock=0&endblock=99999999&page=1&sort=desc`);
      const result = await axios.get(`${base_url}module=account&action=txlist&address=0x77f8fff62C0981f34d1F295515bD3bd3d3f9e5a0&startblock=0&endblock=99999999&page=1&offset=0&sort=asc`);
      setData(result.data);
    };
    fetchData();

  }, []);

  
useEffect(()=>{

  if(!admin){
    navigate("/adminLogin")
  }
  dispatch(getAdmin());
},[dispatch])

  //console.log(data)
  let results = new Set(data['result']);
  console.log(results)
  var numbering = 1;
  return (
    <div className='adminView-main'>
      <h1>List of connected wallet Addresses</h1>
      {Array.from(results).map(result => (
        <Link to={`/Details/${result.from}`}>
        <div className='address-card' key={result.blockNumber}>
          <h2>{numbering++}.</h2>
          {/* <p>Transaction Hash: {result.hash}</p>
          <p>Block Number: {result.blockNumber}</p>
          <p>Timestamp: {new Date(result.timeStamp * 1000).toTimeString() && new Date(result.timeStamp * 1000).toLocaleDateString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit', weekday: 'long',month:'long',year:'numeric' })}</p> */}
          <a href={`/Details/${result.from}`}>{result.from}</a>
          {/* <p>to: {result.to}</p> */}
        </div>
        </Link>
      ))}
    </div>
  )
}
