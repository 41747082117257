import React from 'react'
import '../index.css';

export default function About() {
  return (
    <div className='about_page'>
      <h2 className='about_text'>About the Product</h2>
          <p style={{textAlign:"center"}}>
              The system that allows users to host their web applications and websites in a decentralized manner.The design and implementation of a peer-to-peer network that utilizes blockchain technology for distributed file storage and retrieval. The system leverages Ethereum blockchain to provide secure and decentralized storage for web application files. Additionally. This system presents an innovative approach to web hosting, providing an alternative to traditional centralized web hosting services.
          </p>
          <a href='https://github.com/Qewertyy/Atom'>Source Code</a>
    </div>
  )
}
