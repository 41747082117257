import React from 'react'
import "./global.css"

export default function Navbar() {
    return (
        <>
            <header className='navbar'>
                <a href={"/"}>
                    <h1>Atom</h1>
                </a>
                <ul className='nav'>
                    <li className='nav-items'>
                        <a href={"/"}>
                            Home
                        </a>
                    </li>
                    <li className='nav-items'>
                        <a href={"/About"}>
                            About Product
                        </a>
                    </li>
                    <li className='nav-items'>
                        <a href={"/HostedApps"}>
                            Hosted Apps
                        </a>
                    </li>
                    <li className='nav-items'>
                        <a href={"/Upload"}>
                            Upload Website
                        </a>
                    </li>
                </ul>
            </header>
        </>
    )
}
