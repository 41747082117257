import {
    legacy_createStore as createStore,
    applyMiddleware,
    compose,
    combineReducers,
  } from "redux";
  
  import thunk from "redux-thunk";
import { AdminauthReducer } from "./adminReducer";  

  const rootReducer = combineReducers({
    Adminauth: AdminauthReducer,
  });
  
  const composeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
  
  export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );