import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios";

// IMPORT ADMIN AUTH
import { getAdmin, loginAdmin } from "../Admin/adminLogin";
import { useDispatch, useSelector } from "react-redux";

// css
import '../index.css'

function Login() {
  let navigate = useNavigate()

 let admins = useSelector((store) => store.Adminauth.admins);
 const dispatch =useDispatch();

 
   const [inputValues, setInputValues]= useState({
    address:"",
  });

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputValues({...inputValues, [name]: value });
  };
  //console.log(inputValues)

 function handleLogin(e){
  if(inputValues.address === ""){
    alert("Invalid Address or Password wrong!")
  }
  e.preventDefault();
  let admin = admins.find((e) => e.address === inputValues.address);
  //console.log(admin)
  if(admin){
    navigate('/admin')
    //alert("success")
    dispatch(loginAdmin())
  }
  else{
    alert("Your")
  }
}

useEffect(()=>{
  dispatch(getAdmin());
},[dispatch])

  return (
    <div className="adminLogin-main">
      <h1>Login Form</h1>
      <form className='login-form' >
        <h5>Enter Your Address</h5>
        <input
        value={inputValues.address}
        type="text"
        name='address'
        placeholder='Address' 
        onChange={handleInputs}
        required ></input>
        <button className='login-btn' onClick={handleLogin} type='submit'>Login</button>
      </form>
    </div>
  )
}
export default Login