import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../index.css';
import { isMetaMaskInstalled, isDesiredChain, getAccount, uploadWebsiteDataToContract } from '../blockchain'
import MetaMaskNotInstalled from './MetaMaskNotInstalled';
import NotDesiredChain from './NotDesiredChain';


export default function Main() {
  let [isDesiredChainBool, setIsDesiredChainBool] = useState(true);
  if (!isMetaMaskInstalled()) {
    return <MetaMaskNotInstalled/>;
  }
  isDesiredChain().then((x) => { setIsDesiredChainBool(x); })
  if (!isDesiredChainBool) {
    return <NotDesiredChain/>;
  }
  return <Upload/>;
}

function Upload() {
  return (
    <>
      <UploadFile />
    </>

  )
}

function UploadFile() {
  const HandleFileInput = (e) => {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      //(reader.result);

      uploadWebsiteDataToContract(reader.result);

    }
  }

  // wallet
  let [address, setAddress] = useState("");

  getAccount().then((x) => { setAddress(x); })

  return (
    <>

      <div className="fileuploader-main">
        <div className='float'>
          <div className='wallet-info'>
            <h4>Connected Wallet Address: <p>{address}</p></h4>
          </div>
        </div>
        <span>
          <div className="fileuploader-left">
            <h3>Upload your website here</h3>
            <div className='label'>
              <h4>Click Here to Upload</h4>
              <input type="file" id='fileinput' onChange={HandleFileInput}/>
              <p>At present it only support single html file. In order to merge your files into single html file use tools like glup.js</p>
            </div>
          </div>
          <div className="fileuploader-right">
            <img src="/1.gif" alt="file" />
          </div>
        </span>
      </div>
    </>
  )
}