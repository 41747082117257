import * as types from "./adminTypes";
import axios from "axios";


export const getAdmin = () => (dispatch) => {
  axios
    .get(`http://localhost:4000/Admins`)
    .then((r) => dispatch({ type: types.GET_ADMIN_SUCCESS, payload: r.data }))
    .catch((e) => console.log(e));
};
export const loginAdmin = () => (dispatch) => {
  dispatch({ type: types.ADMIN_LOGIN_SUCCESS });
};
export const logoutAdmin = () => (dispatch) => {
  dispatch({ type: types.ADMIN_LOGOUT_SUCCESS });
};