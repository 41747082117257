import React, { useState, useEffect } from 'react';
import { isMetaMaskInstalled, isDesiredChain, getAccount, getWebsiteDataFromContract, uploadWebsiteDataToContract } from '../blockchain'
import { useNavigate } from 'react-router-dom';
import { urlToHttpOptions } from 'url';
import axios from 'axios';

import {loginuser} from "./AdminLogin"

export default function HostedApps() {
    let navigate = useNavigate()
    const [htmlDataURL, setHTMLDdataURL] = useState(null);
    const [websiteAddress, setWebsiteAddress] = useState("");
    const onButtonClick = (e) => {
        //console.log(e.message)
        if (websiteAddress === "") {
            //console.log(e.message)
            alert("Enter Wallet Address")
        }
        else {
            //console.log(e.message)
            getWebsiteDataFromContract(websiteAddress).then((WebsiteData) => {
                setHTMLDdataURL(WebsiteData);
            });
        }
    }
    
    //console.log(htmlDataURL)
    const ViewInDiffpage = (e) =>{
        setTimeout(() => window.location.reload(), 1000);
        navigate(htmlDataURL, "_blank", 0);

        
    };

    // if(!loginuser){
    //     alert('u r not admin')
    // }
    
    return (
        <div className='HostedAppsDiv'>
            <div className="wallet-details">
            <input onChange={(e) => setWebsiteAddress(e.target.value)} className='search_bar' name="fname" placeholder="Enter wallet address"></input>
                <button onClick={onButtonClick}>Show</button>
            </div>
            <div className="showhostedsites">
                <div className="site-card">
                <iframe title='View in new tab?' onClick={ViewInDiffpage} rel="noreferrer" target='_blank' src={htmlDataURL}></iframe>
                {htmlDataURL != (null)  && (
                    <a href={htmlDataURL} rel="noreferrer" target={'booger'}>View in other tab?</a>
                    )}
                </div>
            </div>
        </div>
    )
}