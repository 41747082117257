import React from 'react';
import '../index.css';

export default function NotDesiredChain() {
    return (
        <div>
            <div className="error_msg">
                <h2>Please connect to the <a rel="noreferrer" target={'_blank'} href="https://mumbai.polygonscan.com/">polygon mumbai testnet network</a> on metamask and reload the page for accessing the website.
                </h2>
            </div>
        </div>
    )
}
