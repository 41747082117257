import React from 'react'
import { Link } from 'react-router-dom'
import {AiOutlineCloudUpload} from 'react-icons/ai'

export default function Home() {
  
  return (
    <div>
      <div className='section1'>
        <div className="intro-text">  
        <h2><strong>Decentralized Hosting</strong><br/>Privacy, Security, and Freedom for All<br/></h2>
        <Link className='Deploybtn' to={"/Upload"}>
        Deploy Now <AiOutlineCloudUpload/>
        </Link>
        </div>
        <div className="intro-img">
        <img className='bg_img' alt='gif' src='/gif.gif'/>
        </div>
      </div>
    </div>
  )
}
