import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import HostedApps from './Components/HostedApps'
import Main from './Components/Upload'
import Login from './Components/AdminLogin'
import About from './Components/About'
import AdminView from './Components/AdminView'
import Details from './Components/Details'

export default function App() {
  return (
    <Router>
        <Routes>
            <Route exact path='/AdminLogin' element={<Login/>}/>
            <Route exact path='/' element={<Home/>}/>
            <Route exact path='/Admin' element={<AdminView/>}/>
            <Route exact path='/About' element={<About/>}/>
            <Route exact path='/Upload' element={<Main/>}/>
            <Route exact path='/HostedApps' element={<HostedApps/>}/>
            <Route exact path='/Details/:address' element={<Details/>}/>
        </Routes>
    </Router>
  )
}