import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getAdmin } from "../Admin/adminLogin";
import { useDispatch, useSelector } from "react-redux";

let base_url = "https://api-testnet.polygonscan.com/api?"

export default function Details() {

  const dispatch =useDispatch();

  const [data, setData] = useState([]);
  const {address} = useParams();
  //console.log(address)
  useEffect(() => {
    
    const fetchData = async () => {
      let apikey = process.env.REACT_APP_Polygon_testnet_apikey;
      //console.log(`${base_url}module=account&action=txlist&address=0x89bf94dB65B3E0930068A22B1abC5b3C648d0004&apikey=${apikey}&startblock=0&endblock=99999999&page=1&offset=5&sort=desc`);
      const result = await axios.get(`${base_url}module=account&action=txlist&address=${address}&apikey=${apikey}&startblock=0&endblock=99999999&page=1&sort=desc`);
      setData(result.data);
    };
    fetchData();
  }, []);

  let results = data['result'];
  var numbering = 1;
  return (
    <div className='mdiv'>
      {results?.map(result => (
        <div className='det' key={result.blockNumber}>
          <h2>{numbering++}.</h2>
          <p>Transaction Hash: {result.hash}</p>
          <p>Block Number: {result.blockNumber}</p>
          <p>Timestamp: {new Date(result.timeStamp * 1000).toTimeString() && new Date(result.timeStamp * 1000).toLocaleDateString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit', weekday: 'long',month:'long',year:'numeric' })}</p>
          <p>from: {result.from}</p>
          <p>to: {result.to}</p>
        </div>
      ))}
    </div>
  )
}
