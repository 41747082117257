import * as types from "./adminTypes";
const initialState = {
  admins: [],
  loading: false,
  error: false,
  isReg: true,
  isAdminLogin: JSON.parse(localStorage.getItem("isAdminLogin")) || false,
  
};

export const AdminauthReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ADMIN_LOGIN_SUCCESS: {
      localStorage.setItem("isAdminLogin", true);
      return {
        ...state,
        loading: false,
        isLogin: true,
      };
    }
    case types.ADMIN_LOGOUT_SUCCESS: {
      localStorage.setItem("isAdminLogin", false);
      localStorage.setItem("userRole", false);
      localStorage.setItem("Admin", JSON.stringify({}));
      return {
        ...state,
        loading: false,
        isLogin: false,
      };
    }
    case types.GET_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        admins: payload,
      };
    }
    default: {
      return state;
    }
  }
};